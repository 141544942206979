import React, {useContext} from 'react';
import {AuthContext} from "../../Context/AuthContext";
import animationData from "../../lottie/document-ok.json";
import {Container, Typography} from "@material-ui/core";
import {Mensagem, StatusContainer} from "./styled";
import Lottie from "react-lottie";
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ContratoCompleto = () => {
    useDocumentTitle("Contrato - Assinado")

    const authContext = useContext(AuthContext);
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Container component="main" maxWidth="md">
            <StatusContainer>

                <Mensagem >
                    Assinado com sucesso!
                </Mensagem>
                <Lottie options={defaultOptions}
                        height={200}
                        width={200}
                />
            </StatusContainer>
        </Container>
    );
};


export default ContratoCompleto;
