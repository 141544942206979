import React, { useState } from "react";

// import { Container } from './styles';

interface ImgLogoUnidadeProps {
  unidadeId: number;
}
const ImgLogoUnidade: React.FC<ImgLogoUnidadeProps & React.HTMLAttributes<HTMLDivElement>>  = ({ unidadeId,className }) => {
  const [existImg, setExistImg] = useState<boolean>(true);
  return (
    <>
      {existImg && (
        <img
          src={`https://storage.googleapis.com/inforcfc_store/logo_unidade/${unidadeId}.jpg`}
          alt=""
          onError={()=>setExistImg(false)}
          className={className}
        />
      )}
    </>
  );
};

export default ImgLogoUnidade;
