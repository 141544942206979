const environment ={
    // url_api_service:"https://localhost:6001/",
    url_api_service:"https://api.inforcfc.app.br/",
    // url_api_infra_service:"http://localhost:5000/"
    // url_api_infra_service:"http://icfc.guiadocondutor.com.br:5000/"
    url_api_infra_service:"https://apiinfra.guiadocondutor.com.br/"

}

export default environment;
