import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Check from "@material-ui/icons/Check";
import clsx from "clsx";
import { format } from "date-fns";
import CopyToClipboard from "react-copy-to-clipboard";
import { FinanceiroBoletosAbertos } from "..";
import { makeStyles } from "@material-ui/core/styles";
import { blue, green, red, yellow } from "@material-ui/core/colors";

interface CardBoletoProperties {
  parcela: FinanceiroBoletosAbertos;
  index: number;
  all?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: 345,
    marginBottom: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 8,
    [theme.breakpoints.down(`sm`)]: {
      //width: "100%",
    },
  },
  boletoPagoLabel: {
    color: green[700],
    fontWeight: "bolder",
  },
  avatarScheduler: {
    backgroundColor: blue[500],
  },
  avatarSchedulerAtrasado: {
    backgroundColor: red[500],
  },
  avatarPago: {
    backgroundColor: green[500],
  },
  avatarEvidente: {
    backgroundColor: yellow[800],
  },
  textStatusScheduler: {
    color: blue[500],
  },
  textStatusSchedulerAtrasado: {
    color: red[500],
  },
  textStatusPago: {
    color: green[500],
  },
  textStatusEvidente: {
    color: yellow[800],
  },
}));
const CardBoleto = ({ parcela, all = false }: CardBoletoProperties) => {
  const classes = useStyles();

  function abrirBoleto(url: string) {
    window.open(url);
  }

  return (
    <div>
      <Card
        className={classes.root}
        style={{
          ...(parcela.evidencia &&
            all && {
              border: `1px solid ${yellow[800]}`,
              backgroundColor: yellow[50],
            }),
          ...(parcela.evidencia &&
            !all && {
              border: `1px solid ${yellow[800]}`,
              backgroundColor: yellow[50],
            }),
        }}
        variant={"elevation"}
        elevation={parcela.evidencia ? 4 : 1}
      >
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={clsx({
                [classes.avatarPago]: parcela.isPago,
                [classes.avatarScheduler]:
                  !parcela.isPago && !parcela.evidencia,
                [classes.avatarSchedulerAtrasado]:
                  !parcela.isPago &&
                  !parcela.evidencia &&
                  parcela.status === "Atrasado",
                [classes.avatarEvidente]: !parcela.isPago && parcela.evidencia,
                // "evidencia":true
              })}
            >
              {!parcela.isPago && parcela.evidencia && <EventIcon />}
              {!parcela.isPago && !parcela.evidencia && <ScheduleIcon />}
              {parcela.isPago && <Check />}
            </Avatar>
          }
          title={parcela.descricao}
          titleTypographyProps={{
            style: {
              fontSize: 14,
              fontWeight: 500,
            },
          }}
          subheader={
            "Vencimento: " + format(new Date(parcela.vencimento), `dd/MM/yyyy`)
          }
          subheaderTypographyProps={{
            style: {
              fontSize: 12,
              color: "#637381",
            },
          }}
        />
        <Typography
          variant="body2"
          className={clsx({
            [classes.textStatusPago]: parcela.isPago,
            [classes.textStatusScheduler]:
              !parcela.isPago && !parcela.evidencia,
            [classes.textStatusSchedulerAtrasado]:
              !parcela.isPago &&
              !parcela.evidencia &&
              parcela.status === "Atrasado",
            [classes.textStatusEvidente]: !parcela.isPago && parcela.evidencia,
            // "evidencia":true
          })}
          style={{
            position: `absolute`,
            right: 16,
            bottom: 16,
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {parcela.status}
        </Typography>
        {parcela.evidencia && parcela.boleto?.linhaDigitavel && !all && (
          <CardContent style={{ whiteSpace: "pre-wrap" }}>
            <CopyToClipboard
              text={parcela.boleto.linhaDigitavel}
              onCopy={() => alert(`Copiado`)}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  style={{
                    whiteSpace: "pre-wrap",
                    fontSize: 11,
                    fontWeight: 600,
                    marginBottom: 8,
                  }}
                >
                  Linha Digitável:
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  style={{ whiteSpace: "pre-wrap", fontSize: 11 }}
                >
                  <Link
                    color={"primary"}
                    noWrap={false}
                    style={{
                      width: "100%",
                      overflowWrap: "break-word",
                    }}
                  >
                    {parcela.boleto.linhaDigitavel}
                  </Link>
                </Typography>
              </div>
            </CopyToClipboard>
          </CardContent>
        )}
        {parcela.evidencia && parcela.boleto?.linhaDigitavel && !all && (
          <CardActions disableSpacing>
            <Button
              size="small"
              color="primary"
              onClick={() => abrirBoleto(parcela.boleto.urlParcela)}
            >
              {parcela.boleto.linhaDigitavel ? "ver boleto" : "ver cobrança"}
            </Button>
            {parcela.boleto?.linhaDigitavel && (
              <CopyToClipboard
                text={parcela.boleto.linhaDigitavel}
                onCopy={() => alert(`Copiado`)}
              >
                <Button size="small" color="primary">
                  Copiar código
                </Button>
              </CopyToClipboard>
            )}
          </CardActions>
        )}
      </Card>
    </div>
  );
};
export default CardBoleto;