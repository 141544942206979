import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { faHeart } from '@fortawesome/free-solid-svg-icons';
import {
  faAddressCard,
  faCalendarAlt,
} from '@fortawesome/free-regular-svg-icons';

import { makeStyles } from '@material-ui/core/styles';
import { Field as FieldFormik, Form, Formik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import {
  Box,
  CircularProgress,
  Container,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'date-fns/parse';
import { isDate } from 'date-fns';
import ImgLogoUnidade from '../Shared/ImgLogoUnidade';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { tr } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '260px',
    marginBottom: '4rem',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Created with by <FontAwesomeIcon icon={faHeart} />
      &nbsp;
      <Link color="inherit" href="http://inforcfc.com.br/">
        Inforcfc.com.br
      </Link>{' '}
      <br />
      {'Copyright © '}
      {new Date().getFullYear()}
      {'..'}
    </Typography>
  );
}

export interface LoginProps {
  cpf: string;
  dataNascimento: string;
}
const ContratoLoginInforCFC: React.FC = () => {
  useDocumentTitle('Contrato - login');
  const [isSubmitting, setSubmitting] = useState(true);
  const authContext = useContext(AuthContext);
  const { alert, setAlert, aluno } = authContext;
  let { unidadeId, codigo } = useParams<any>();

  const classes = useStyles();

  function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const submit = async () => {
    let result = await authContext.login({
      unidadeId,
      codigo,
      cpf: null,
      dataNascimento: '',
    });
    if (!result) {
      setSubmitting(false);
      return;
    }
    setAlert({
      ...alert,
      open: true,
      type: 'success',
      message: `Olá ${toTitleCase(result.aluno.nome).split(' ')[0]}`,
    });
  };

  useLayoutEffect(() => {
    submit();
  }, [codigo]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <ImgLogoUnidade unidadeId={unidadeId} className={classes.logo} />

          {isSubmitting && <CircularProgress />}
          <br />
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default ContratoLoginInforCFC;
