import React, {useContext, useEffect, useState} from 'react';
import SignaturePad from "signature_pad";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import trimCanvas from 'trim-canvas'
import {BotaoFinalizar, ContainerAssinatuna, ContainerLimpar, ContainerWapper, Titulo, TituloCima} from "./styled";
import {
    Button,
    Typography
} from "@material-ui/core";
import {AuthContext} from "../../Context/AuthContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import {SalvarContrato} from "../../services/AlunoContratoService";
import {useHistory} from "react-router-dom";
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ContratoAssinarPage = () => {
    useDocumentTitle("Contrato - Assinatura")

    const authContext = useContext(AuthContext);
    const history = useHistory();
    const {alert, setAlert} = authContext;
    const {alunoId} = authContext.aluno
    const [signaturePad, setSignature] = useState<SignaturePad>(null as any);
    const [submitting, setSubmitting] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function resizeCanvas() {
        const canvas = document.getElementById('signature-pad') as any;

        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
    }

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
        const canvas = document.getElementById('signature-pad') as any;

        const signaturePad = new SignaturePad(canvas, {
            // backgroundColor: 'rgb(255, 255, 255)',
            velocityFilterWeight: 0,
            throttle: 0


            // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
        });
        setSignature(signaturePad);
        window.onresize = resizeCanvas;
        resizeCanvas();
    }, [])

    function trimAssinatura() {
        let copy = document.createElement('canvas') as any;
        const canvas = document.getElementById('signature-pad') as any;
        copy.width = canvas.width
        copy.height = canvas.height
        copy.getContext('2d').drawImage(canvas, 0, 0)
        // then trim it
        return trimCanvas(copy)
    }

    async function finalizar() {
        setSubmitting(true)
        if (signaturePad.isEmpty()) {
            setAlert({
                ...alert,
                vertical: "top",
                open: true,
                type: "error",
                message: "Verifique sua assinatura!"
            });
            setSubmitting(false)
            return;
        }



        const data =trimAssinatura().toDataURL('image/png' )




        try {
            await SalvarContrato({
                alunoId,
                imgAssinatura: data
            } as any)
            setAlert({
                ...alert,
                vertical: "top",
                open: true,
                type: "success",
                message: "Salvo com sucesso!"
            });
            history.push("/contrato/completo")

        } catch (e: any) {
            console.log(e);
            const bodyErro = e?.response?.data?.result ?? 'erro';
            setSubmitting(false)

            if (bodyErro) {
                setAlert({
                    ...alert,
                    vertical: "top",
                    open: true,
                    type: "error",
                    message: bodyErro,
                    title: "Erro ao salvar!"
                });
            } else {
                setAlert({
                    ...alert,
                    vertical: "top",
                    open: true,
                    type: "error",
                    message: "Erro ao salvar!"
                });
            }

        }
    }

    return (
      <>
        <ContainerAssinatuna
          maxWidth="sm"
          style={{
            ...(windowWidth > 600 && {
              overflowY: 'auto',
              height: '100dvh',
            }),
          }}
        >
          <Titulo variant="h5">Assine abaixo com o dedo ou mouse.</Titulo>
          <TituloCima variant="body2">
            Tudo bem se não ficar 100% idêntico ao papel.
          </TituloCima>
          <ContainerWapper>
            <canvas
              id="signature-pad"
              className="signature-pad"
              width="100%"
              height="100%"
            />
            <Typography className="sua-assinatura">sua assinatura</Typography>
          </ContainerWapper>
          <ContainerLimpar>
            {submitting && <LinearProgress />}

            <Button
              variant="text"
              disabled={submitting}
              onClick={() => signaturePad.clear()}
            >
              Limpar
            </Button>
            <br />
            <BotaoFinalizar
              disabled={submitting}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => finalizar()}
            >
              {submitting ? 'AGUARDE...' : 'FINALIZAR'}
            </BotaoFinalizar>
          </ContainerLimpar>
          <br />
        </ContainerAssinatuna>
      </>
    );
};

export default ContratoAssinarPage;
