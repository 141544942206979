import axios from "axios";
import React, {useEffect, useState} from "react";

import {useParams} from "react-router-dom";
import "./style.css";
import {RastreioRetorno} from "./RastreioRetorno";
import { format } from 'date-fns'

import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem, TimelineOppositeContent,
    TimelineSeparator
} from "@material-ui/lab";
import {Box, CircularProgress, Typography} from "@material-ui/core";
import environment from "../services/environment";
import useDocumentTitle from "../hooks/useDocumentTitle";

const Correios: React.FC = () => {
    let {unidadeId, codigo} = useParams<any>();
    useDocumentTitle("Rastreio de encomendas")
    const [rastreios, setRastreios] = useState<RastreioRetorno[]>(undefined as any);

    useEffect(() => {
        carregaRastreio(codigo)
    }, [codigo]);

    let carregaRastreio = async (rastreio: string) => {
        const lista = await axios.get<RastreioRetorno[]>(`${environment.url_api_service}api/ServicosExternos/correios/rastreio/${rastreio}`)
        setRastreios(lista.data)

    }

    let imgError = (e: any) => {
        e.target.onerror = null;
        e.target.src = "";
        e.target.style.display = "none";
        e.target.remove()
    };
    return (
        <div className="container-iframe">
            <div className="logo">
                <img
                    src={`https://storage.googleapis.com/inforcfc_store/logo_unidade/${unidadeId}.jpg`}
                    alt=""
                    onError={imgError}
                />
                <small>
                    Criado por &nbsp;
                    <a target="_blank" rel="noreferrer" href="http://inforcfc.com.br/">
                        Inforcfc.com.br
                    </a>{" "}

                    {"Copyright © "}
                    {new Date().getFullYear()}
                    {"."}
                </small>

                {!rastreios &&  <div className="carregando">
                    {rastreios === undefined &&     <CircularProgress   />}
                    {rastreios===null &&
                    <Typography variant="h4" component="h2" color="error">
                        Código não encontrado!
                    </Typography>
                    }
                </div>}
            </div>

            <Timeline align="left">

                {rastreios && rastreios.map((x, i) => {
                    return <TimelineItem key={i}>
                        <TimelineOppositeContent>
                            {format(new Date(x.data),"dd/MM/yyyy HH:mm")}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color={i === 0 ? "secondary" : "grey"}/>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant="subtitle2" component="p">
                                {x.status}
                            </Typography>


                            <Typography variant="caption" component="small">
                                {x.local}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                })}


            </Timeline>

        </div>
    );
};

export default Correios;
