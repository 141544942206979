import React, {useContext, useMemo} from 'react';
import {AppBar, createStyles, Link, Theme, Toolbar, Tooltip} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudDownloadAlt, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../../Context/AuthContext";
import ImgLogoUnidade from "../../Shared/ImgLogoUnidade";

const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            align: "center",
            textAlign: "center",

        },
        toolbar: {
            display: "flex",
            justifyContent: "space-between"
        }, container: {
            overflow: "hidden",
            display: "block"
        },
        logo: {
            maxWidth: "102px",
            maxHeight:"59px"
        },
    }),
);
const ContratoMasterPage = ({children, arrow}: any) => {
    const authContext = useContext(AuthContext);
    const {alunoId, matriculaId, autoescolaId,unidadeId} = authContext.aluno;

    const history = useHistory();
    const classes = useStyles();
    const url = useMemo(() => `https://storage.googleapis.com/ftp_docs/${autoescolaId}/AlunoContratos/${authContext.statusContrato.id!==2?'original-':'assinado-'}${alunoId}-${matriculaId}.pdf`, [authContext.aluno]);
    return (
        <div className={classes.container + " container"}>
            <AppBar position="relative" color="inherit">
                <Toolbar className={classes.toolbar}>
                    {
                        !arrow ? <div></div> : <Tooltip title="Voltar">
                            <Link onClick={() => history.goBack()}>
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={faArrowLeft }
                                />
                            </Link>

                        </Tooltip>

                    }

                    <div className={classes.title}>
                            <ImgLogoUnidade style={{maxWidth:'102px',maxHeight:'51px'}} unidadeId={unidadeId}  className={classes.logo}/>

                            {/*<img width="102" height="51" alt="InforCFC" src="/assets/img/logo_infor_pequeno.png"/>*/}
                            {/*<img width="102" height="51" alt="InforCFC" src="/assets/img/logo_infor_pequeno.png"/>*/}
                    </div>

                    <Tooltip title="Baixar contrato">
                        <a href={url} target="_blank" style={{color:"black"}}> <FontAwesomeIcon
                            size="lg"

                            icon={faCloudDownloadAlt}
                        /></a>
                    </Tooltip>

                </Toolbar>
            </AppBar>
            {children}

        </div>
    );
};

export default ContratoMasterPage;
