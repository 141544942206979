import { useCallback, useState } from 'react';
import storage from "../Shared/storage";


export default function useStorage<T>(key:string) {
  const [state, setState] = useState<T>(() => storage.get<T>(key) as any);

  const set = useCallback(newValue => {
    storage.set(key, newValue);
    setState(newValue);
  }, [key]);

  const remove = useCallback(() => {
    storage.remove(key);
    setState(null as any);
  }, [key]);

  return [state, set as any, remove];
}