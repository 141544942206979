import styled from "styled-components";

export const StatusContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 5rem;
  text-align: center;
  text-transform: uppercase;
  .mensagem{
    margin-top: 8rem;
  }
`;


