import React, {useContext} from 'react';
import {Button, Container, TextField, Typography} from "@material-ui/core";
import {AuthContext} from "../../Context/AuthContext";
import {BotaoAceitarContrato} from "../ContratoLerPage/styled";
import {Link} from "react-router-dom";
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ContratoVerificarPage = () => {
    useDocumentTitle("Contrato - Verificação")

    const authContext = useContext(AuthContext);
    const {nome, dataNascimento, cpf} = authContext.aluno;
    return (
        <Container maxWidth="xs" style={{paddingTop: '1rem'}}>

            <Typography variant="h5"
                        style={{paddingTop: '1rem', paddingBottom: '2rem', width: '100%', textAlign: 'center'}}>Confirme
                seus dados.</Typography>
            <TextField fullWidth={true}  InputProps={{
                readOnly: true,
            }} value={nome} label="Nome completo">

            </TextField>
            <TextField style={{marginTop: '1rem'}} fullWidth={true} InputProps={{
                readOnly: true,
            }} value={dataNascimento}
                       label="Data nascimento">

            </TextField>

            <TextField  InputProps={{
                readOnly: true,
            }} style={{marginTop: '1rem'}} fullWidth={true} value={cpf} label="Cpf">

            </TextField>

            <Button  component={Link} to="/contrato/assinar"  fullWidth style={{
                backgroundColor: "#19A597",
                color: "white",
                marginTop:"2rem"
            }} size="large" variant="contained">CONTINUAR</Button>
        </Container>
    );
};

export default ContratoVerificarPage;
