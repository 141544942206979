import { Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { BuscarCadastrosAutoescolas, CadastroAutoescola } from "../../services/AlunoService";
import CardAutoescola from "./CardAutoescola";
import { Alert, AlertTitle } from "@material-ui/lab";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";

const maskCPF = () => {
  return (value: string) => {
    if (!value) return value;

    const cleanValue = value.replace(/\D/g, "");

    if (cleanValue.length === 4)
      return cleanValue.replace(/(\d{3})(\d)/, "$1.$2");
    if (cleanValue.length === 7)
      return cleanValue.replace(/(\d{3})(\d{3})(\d)/, "$1.$2.$3");
    if (cleanValue.length >= 10) {
      return cleanValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1.$2.$3-");
    }
    return value;
  };
}
const maskPhone =() => {
  return (value: string) => {
    if (!value) return value;
    const cleanValue = value.replace(/\D/g, "");

    if (cleanValue.length === 3) return cleanValue.replace(/(\d{2})/, "($1)");
    if (cleanValue.length >= 8)
      return cleanValue.replace(/(\d{2})(\d{5})/, "($1)$2-");

    return value;
  };
}
export function removerCaracteresEspeciais(texto: string): string {
  if (!texto) return texto;
  const caracteresEspeciais = /[^a-zA-Z\d]/g;
  return texto.toString().replace(caracteresEspeciais, "");
}
const logoMinhacnh =
  "https://play-lh.googleusercontent.com/FZC9SzpOzEdId8eSmfV6JBAR8gPZzA9K_J-U1CN64VGpwYZnq4YvM1tWnNWG1aKrU7M=w240-h480-rw";

  const useStyles = makeStyles({
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: "#637381",
    },
  });
  const BuscarAutoescola: React.FC = () => {
  const [radioValue, setRadioValue] = useState<"telefone" | "cpf">("telefone");
  const [buscaValue, setBuscaValue] = useState("");
  const [autoescolas, setAutoescolas] = useState <CadastroAutoescola[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const classes = useStyles();

  const WIDTH = window.innerWidth;
  const handleSubmit = async () => {
    setError('');
    setIsLoading(true);
    try {
      const response = await BuscarCadastrosAutoescolas(
        removerCaracteresEspeciais(buscaValue), radioValue
      );
      if (response.length === 0) {
        setError('Não foi possível encontrar o cadastro');
      }
      setAutoescolas(response);
    } catch (error) {
      console.log(error);
      setError('Não foi possível encontrar o cadastro');
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10,
        width: 340,
        padding: 8,
        ...(autoescolas.length > 0 && { overflowY: "scroll" }),
      }}
    >
      <Card
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 8,
          paddingBottom: 8,
          marginBottom: 16,
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 4,
          }}
        >
          <img
            alt="logo"
            src={logoMinhacnh}
            width={130}
            height={"auto"}
            style={{
              borderRadius: 8,
            }}
          />
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 24,
            marginBottom: 16,
          }}
        >
          <Typography
            variant="body2"
            style={{
              marginBottom: 16,
              fontSize: 12,
              fontWeight: 500,
              color: "#637381",
            }}
          >
            Olá! Nós somos a MINHA CNH ONLINE, a empresa que gerencia as notificações das autoescolas. Se você
            deseja descobrir em qual autoescola está acontecendo um serviço,
            basta inserir os dados abaixo, e nós mostraremos os contatos
            correspondentes. Simples assim!
          </Typography>
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <FormControl
              component="fieldset"
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              }}
            >
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={radioValue}
                onChange={(e) =>
                  setRadioValue(e.target.value as "telefone" | "cpf")
                }
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="telefone"
                  control={<Radio size="small" style={{ fontSize: 10 }} />}
                  label="Telefone"
                  classes={{
                    label: classes.label,
                  }}
                />
                <FormControlLabel
                  value="cpf"
                  control={<Radio size="small" style={{ fontSize: 10 }} />}
                  label="CPF"
                  classes={{
                    label: classes.label,
                  }}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              id="outlined-basic"
              label={
                radioValue === "telefone"
                  ? "Digite seu Telefone"
                  : "Digite seu CPF"
              }
              variant="outlined"
              size="small"
              value={
                radioValue === "telefone"
                  ? maskPhone()(buscaValue)
                  : maskCPF()(buscaValue)
              }
              onChange={(e) => setBuscaValue(e.target.value)}
              inputProps={{
                maxLength: 14,
              }}
              style={{
                width: "100%",
                marginBottom: 32,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
              style={{
                width: "100%",
              }}
            >
              {isLoading ? "Buscando..." : "Buscar autoescola"}
            </Button>
          </Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 16,
              padding: 0
            }}
          >
            <Typography variant="body2" style={{ fontSize: 11 }}>
              Baixe nosso App:
            </Typography>
            <a
              href="https://play.google.com/store/apps/details?id=inforcfc.minhacnh"
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 8,
                textDecoration: "none",
              }}
            >
              <AndroidIcon style={{ color: "#00a44f" }} />
              <Typography
                variant="body2"
                style={{ fontSize: 12, marginLeft: 4 }}
              >
                Android
              </Typography>
            </a>
            <a
              href="https://apps.apple.com/us/app/minha-cnh-online/id1554140469"
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 16,
                marginBottom: 2,
                textDecoration: "none",
              }}
            >
              <AppleIcon fontSize="small" />
              <Typography
                variant="body2"
                style={{ fontSize: 12, marginLeft: 4 }}
              >
                Apple
              </Typography>
            </a>
          </div>
        </Container>
      </Card>

      {autoescolas.length > 0 && (
        <div
          style={{
            height: WIDTH < 600 ? "30vh" : "40vh",
          }}
        >
          {autoescolas.map((autoescola) => (
            <>
              <CardAutoescola autoescola={autoescola} />
            </>
          ))}
        </div>
      )}
      {error && (
        <Alert elevation={6} variant="filled" severity={"error"}>
          {<AlertTitle>{`${radioValue} não encontrado`}</AlertTitle>}
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default BuscarAutoescola;
