import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import Lottie from "react-lottie";
import aguardando from "../lottie/agardando.json";
import { useParams } from "react-router-dom";
import {
  BuscarFinanceiroParcelas,
  FinanceiroParcela,
} from "../services/AlunoService";
import { makeStyles } from "@material-ui/core/styles";
import ImgLogoUnidade from "../Shared/ImgLogoUnidade";
import useDocumentTitle from "../hooks/useDocumentTitle";
import CardBoleto from "./CardBoletos";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import { blue } from "@material-ui/core/colors";

export interface FinanceiroBoletosAbertos extends FinanceiroParcela {
  evidencia: boolean;
}
function ordenaArray(
  a: FinanceiroBoletosAbertos,
  b: FinanceiroBoletosAbertos
): number {
  // Primeiro, verificamos o status "Atrasado"
  if (a.status === "Atrasado" && b.status !== "Atrasado") {
    return -1; // "a" vem antes de "b" se "a" estiver atrasado
  } else if (a.status !== "Atrasado" && b.status === "Atrasado") {
    return 1; // "b" vem antes de "a" se "b" estiver atrasado
  } else {
    // Se ambos ou nenhum estiverem atrasados, comparamos pelo vencimento
    const dataA = new Date(a.vencimento).getTime();
    const dataB = new Date(b.vencimento).getTime();
    return dataA - dataB; // Ordena pelo vencimento mais próximo
  }
}

const option = {
  loop: true,
  autoplay: true,
  animationData: aguardando,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const useStyles = makeStyles((theme) => ({
  containerLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 24,
  },
  cardContainer: {
    display: "flex",
    maxWidth: "100vw",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 16,
    overflowY: "auto",
    height: window.innerHeight - window.innerHeight * 0.33,
    [theme.breakpoints.down(`sm`)]: {
      height: window.innerHeight - window.innerHeight * 0.3,
    },
  },
  downloadApp: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 16,
    padding: 12,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 8,
    border: `1px solid ${blue[800]}`,
    backgroundColor: blue[50],
    color: blue[800],
    [theme.breakpoints.down(`sm`)]: {
      flexDirection: "column",
      paddingLeft: 16,
      paddingRight: 16,
      gap: 16,
    },
  },
}));
const ParcelasFinanceiro = () => {
  useDocumentTitle("Financeiro");

  const classes = useStyles();
  const [parcelas, setParcelas] = useState<FinanceiroBoletosAbertos[]>(
    null as any
  );
  let { autoescolaId, unidadeId, alunoId, matriculaId } = useParams<any>();
  console.log();
  
  useEffect(() => {
    BuscarFinanceiroParcelas({
      autoescolaId,
      unidadeId,
      alunoId,
      matriculaId,
    })
      .then((x) => {

        let views = x as FinanceiroBoletosAbertos[];

        const boletoEvidencia = views.findIndex((boleto) => !boleto.isPago);
        // console.log(views);
        
        if (boletoEvidencia > -1) views[boletoEvidencia].evidencia = true; 

        setParcelas(views.sort(ordenaArray));
      })
      .catch((x) => {
        alert(x.error);
      });
  }, [alunoId, autoescolaId, matriculaId, unidadeId]);

  return (
    <div>
      <div className={classes.containerLogo}>
        <ImgLogoUnidade unidadeId={unidadeId} />
      </div>

      {parcelas === null ? (
        <Box mt="30px">
          <Lottie options={option} height={250} width={250} />
          <Typography variant={"h2"} align={"center"}>
            Aguarde...
          </Typography>
        </Box>
      ) : (
        <>
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            <Typography
              variant="body1"
              align="center"
              style={{ marginBottom: 2 }}
            >
              Aqui você poderá visualizar suas parcelas com a autoescola.
            </Typography>
            <Typography
              variant="body2"
              align="center"
              color="textSecondary"
              style={{ fontWeight: 500 }}
            >
              A opção de pagamento está disponível apenas para a parcela de
              vencimento mais próximo.
            </Typography>
            <div className={classes.downloadApp}>
              <Typography
                variant="body2"
                align="center"
                style={{ fontSize: 14, fontWeight: 500 }}
              >
                Baixe nosso App e tenha suas parcelas na palma da mão:
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <a
                  href="https://play.google.com/store/apps/details?id=inforcfc.minhacnh"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 8,
                    textDecoration: "none",
                  }}
                >
                  <AndroidIcon style={{ color: "#00a44f" }} />
                  <Typography
                    variant="body2"
                    style={{ fontSize: 12, marginLeft: 4, marginTop: 4 }}
                  >
                    Android
                  </Typography>
                </a>
                <a
                  href="https://apps.apple.com/us/app/minha-cnh-online/id1554140469"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 16,
                    marginBottom: 2,
                    textDecoration: "none",
                  }}
                >
                  <AppleIcon fontSize="small" />
                  <Typography
                    variant="body2"
                    style={{ fontSize: 12, marginLeft: 4, marginTop: 4 }}
                  >
                    Apple
                  </Typography>
                </a>
              </div>
            </div>
          </Container>
          <Container className={classes.cardContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"h6"}
                align={"center"}
                style={{ fontSize: 16, marginBottom: 16 }}
              >
                Parcela disponível para pagamento
              </Typography>
              {parcelas.length &&
                parcelas.map((parcela, i) => {
                  if (parcela.evidencia)
                    return (
                      <CardBoleto
                        parcela={parcela}
                        key={String(parcela.id)}
                        index={i}
                      />
                    );
                  return null;
                })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"h6"}
                align={"center"}
                style={{ fontSize: 16, marginBottom: 16 }}
              >
                Suas Parcelas
              </Typography>
              {parcelas.length ? (
                parcelas.map((parcela, i) => (
                  <CardBoleto
                    parcela={parcela}
                    key={String(parcela.id)}
                    index={i}
                    all={true}
                  />
                ))
              ) : (
                <h2>Sem resultados</h2>
              )}
            </div>
          </Container>
        </>
      )}
    </div>
  );
};





export default ParcelasFinanceiro;
