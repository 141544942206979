import styled from "styled-components";
import {Button, Typography} from "@material-ui/core";

export const ContainerContratoLer = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 68px;
  position: absolute;
  overflow: hidden!important;

  //margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
`;

export const MensagemLerContrato = styled(Typography)`
  text-align: center;


`;
export const LinkContrato = styled(Typography)`
  text-align: center;
  display: block;
  padding-top: 1rem;
`;

export const BotaoAceitarContrato = styled(Button)`
  background-color: #19A597 !important;
  color: white !important;

`;

export const ContainerBotao = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
   width: 100%;
  user-select: none;
`;
export const ContainerCarregandoPdf= styled.div`
  
  z-index: 1;
 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
