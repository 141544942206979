import {AlunoContratoLoginView} from './../models/AlunoContratoLoginView';
import {Post, ServiceBase} from "./ServiceBase";
import {AlunoMin} from "../models/AlunoMin";
import {AlunoContratoDetalhe} from "../models/AlunoContratoDetalhe";

const urlBase = `${ServiceBase.instance.defaults.baseURL}candidato/contrato/`;

export const Logar = (login: AlunoContratoLoginView) => {
    return Post<{ token: string,aluno:AlunoMin, status: { id: number, descricao: string } }>(urlBase + 'login', login)
}

export const SalvarContrato = (alunoContratoDetalhe:AlunoContratoDetalhe)=>{
    return Post<any>(urlBase + 'salvar/assinatura', alunoContratoDetalhe)

}