import React, {useContext, useEffect, useMemo} from 'react';
import {AuthContext} from "../../Context/AuthContext";
import {StatusContainer} from "./styles";
import Lottie, {} from "react-lottie";
import aguardando from "../../lottie/agardando.json";
import atencao from "../../lottie/atencao.json";
import docOk from "../../lottie/document-ok.json";

import {Button, Container, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ContainerBotao} from "../ContratoLerPage/styled";
import useDocumentTitle from '../../hooks/useDocumentTitle';

const option = {
    loop: false,
    autoplay: true,
    animationData: null,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const ContratoStatusPage = () => {
    useDocumentTitle("Contrato - Status")
    const authContext = useContext(AuthContext);
    const defaultOptions = useMemo(() => {
        if (authContext.statusContrato.id === 1)
            return {...option, animationData: aguardando,loop: true}
        if (authContext.statusContrato.id === 2)
            return {...option, animationData: docOk}
        if (authContext.statusContrato.id >= 3)
            return {...option, animationData: atencao}

        return null;
    }, [authContext?.statusContrato.id]) as any;


    // useEffect(()=>{
    //     switch (authContext.statusContrato.id) {
    //         case 1
    //     }
    // },[])

    return (
        <Container component="main" maxWidth="md">
            <StatusContainer>
                <Lottie options={defaultOptions}
                        height={250}
                        width={250}
                />
                <Typography className="mensagem" variant="h4"
                            color="textSecondary">{authContext.statusContrato.descricao}
                </Typography>
                {(authContext.statusContrato.id ===2 || authContext.statusContrato.id >=3) && <Button component={Link} to="/contrato/ler"   style={{
                    backgroundColor: "#19A597",
                    color: "white",
                    marginTop:"50px"

                }}  variant="contained">{authContext.statusContrato.id===2?"VISUALIZAR CONTRATO ASSINADO":"CONTINUAR"}</Button>}
            </StatusContainer>
        </Container>
    );
};

export default ContratoStatusPage;
