import {Get, ServiceBase} from "./ServiceBase";
 

const urlBase = `${ServiceBase.instance.defaults.baseURL}candidato/`;

export const PainelNotificacaoBuscarAluno = (token: string) => {
    return Get<AlunoNotificacao>(`${urlBase}painel/notificacao/buscar/${token}`)
}
export const PainelNotificacaoSalvar = (token: string, receberNotificacao: boolean) => {
    return Get(`${urlBase}painel/notificacao/salvar/${token}/${receberNotificacao}`)
}
export const BuscarRedeSociaisTodasUnidades = (autoescolaId: Number) => {
    return Get<Unidade[]>(`${ServiceBase.instance.defaults.baseURL}RedeSocial/autoescola/${autoescolaId}/completo`)
}
export const BuscarFinanceiroBoletosAbertos = (token:string) => {
    return Get<FinanceiroBoletosAbertos[]>(`${urlBase}financeiro/boletos-abertos/${token}`)
}
export const BuscarCadastrosAutoescolas = (value: string, type: 'telefone' | 'cpf') => {
    return Get<CadastroAutoescola[]>(`${urlBase}painel/notificacao/buscar/cpf-ou-telefone?${type}=${value}`)
}
export const BuscarFinanceiroParcelas = ({ autoescolaId, unidadeId, alunoId, matriculaId }: GetFinanceiroParcelas) => {
    const url = ServiceBase.instance.defaults.baseURL
    return Get<FinanceiroParcela[]>(`${url}Carne/v2/autoescola/${autoescolaId}/unidade/${unidadeId}/alunoId/${alunoId}/matriculaId/${matriculaId}/listar`);
}
export interface AlunoNotificacao   {
    id: number,
    nome: string,
    unidadeId: number,
    autoescolaId: number,
    receberNotificacao: boolean
}
export interface Unidade {

    id:number;
    nome:string;
    autoescolaId: number;
    fone: string;
    endereco: string;
    enderecoNumero: string;
    bairro: string;
    cidade: string;
    uf: string;
    whatsTel: string;
    redeSocial?: RedeSocial;
}

export interface RedeSocial {
    unidadeId: number;
    autoescolaId: number;
    facebook: string;
    twitter: string;
    whatsapp: string;
    instagram: string;
}

export interface FinanceiroBoletosAbertos {
    vencimento: string;
    valor: number;
    descricao: string;
    codigoBarra: string;
    linhaDigitavel: string;
    urlParcela: string;
    sit: string;
}

export interface CadastroAutoescola {
    alunoId: number;
    alunoNome: string;
    unidadeNome: string;
    unidadeId: number;
    autoescolaId: number;
    codigo: string;
}

export interface GetFinanceiroParcelas {
    autoescolaId: number;
    unidadeId: number;
    alunoId: number;
    matriculaId: number;
}
export interface FinanceiroParcela {
    id: number
    boleto?: Boleto
    alunoId: number
    matriculaId: number
    vencimento: string
    data: string
    valor: number
    descricao: string
    situacao: string
    nossoNumero: number
    usuarioId: number
    autoescolaId: number
    unidadeId: number
    movId: number
    isBoleto: boolean
    isAtrasado: boolean
    isPago: boolean
    existePagamentoOnline: boolean
    isPermitidoPagar: boolean
    isPermitidoGerar: boolean
    status: string
    vencimentoStr: string
}

export interface Boleto {
    id: number
    carneId: number
    contaId: number
    formaPagamento: number
    status: number
    identificadorParcela: string
    linhaDigitavel: string
    codigoBarra: string
    urlParcela: string
    taxa: number
    autoescolaId: number
    gateway: number
    ultimaAlteracao: string
    usuario: number
}
