

import React, {useContext, useEffect, useState} from 'react';
import {
    AlunoNotificacao,
    BuscarRedeSociaisTodasUnidades,
    PainelNotificacaoBuscarAluno,
    PainelNotificacaoSalvar, Unidade
} from "../services/AlunoService";
import {Box, CircularProgress, Container} from "@material-ui/core";
import AlunoNotificacaoCard from "./AlunoNotificacaoCard";
import {AuthContext} from "../Context/AuthContext";
import {useParams} from "react-router-dom";
import UnidadeContatoCard from "./UnidadeContatoCard";
import useDocumentTitle from '../hooks/useDocumentTitle';

const NotificacaoWhatsappPage = () => {
    useDocumentTitle("Notificação Whatsapp")
    const [aluno, setAluno] = useState<AlunoNotificacao>(undefined as any);
    const authContext = useContext(AuthContext);
    const {alert, setAlert} = authContext;
    let {unidadeId, codigo} = useParams<any>();
    const [erro, setErro] = useState<string>();
    const [unidades, setUnidades] = useState<Unidade[]>(undefined as any);


    useEffect(() => {
        async function buscar() {
            try {
                const alunoRetorno = await PainelNotificacaoBuscarAluno(codigo)
                if (!alunoRetorno) {
                    setErro("Aluno não encontrado!")
                } else {
                    setAluno(alunoRetorno)
                }
            } catch (e) {
                setErro("Erro interno, tente novamente mais tarde.")
                setAlert({...alert, title: "Erro!", message: "Erro ao buscar o aluno", type: "error", open: true})

            }

        }

        buscar()
    }, [codigo]);

    useEffect(() => {
        if (!aluno?.autoescolaId) return;

        async function init() {
            try {
                const unidadesRest = await BuscarRedeSociaisTodasUnidades(aluno.autoescolaId)
                setUnidades(unidadesRest)
            } catch (e) {

                setUnidades([])

            }
        }

        init()
    }, [aluno]);
    return (
        aluno === undefined ? <Box p={5} mt={10} display="flex" flexDirection="column" justifyContent="center"
                                   alignItems="center">
                <h1>{erro ? erro : "Aguarde buscando suas informações..."}</h1>
                {!erro && <CircularProgress style={{marginTop: 30}}/>}
            </Box> :

                <Box style={{overflow:"scroll",height:"100%",position:"fixed"}} mt={4} display="flex" justifyContent="space-between" flexDirection="row" flexWrap="wrap"  >
                    <Box p={5}>
                        <AlunoNotificacaoCard codigo={codigo} setAlert={setAlert} alunoModel={aluno}/>
                    </Box>

                    {unidades?.map(x=><Box p={2}><UnidadeContatoCard  key={x.id} unidade={x}/></Box>)}
                </Box>

    );
};

export default NotificacaoWhatsappPage;
