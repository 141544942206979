import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Correios from './Correios';
import Contrato from './Contrato';
import ContratoStatusPage from './Contrato/ContratoStatusPage';
import { AuthProvider } from './Context/AuthContext';
import React from 'react';
import ContratoLerPage from './Contrato/ContratoLerPage';
import ContratoAssinarPage from './Contrato/ContratoAssinarPage';
import ContratoMasterPage from './Contrato/ContratoMasterPage';
import ContratoVerificarPage from './Contrato/ContratoVerificarPage';
import ContratoCompleto from './Contrato/ContratoCompleto';
import NotificacaoWhatsappPage from './NotificacaoWhatsapp';
import FinanceiroBoleto from './FinanceiroBoleto';
import ReactGA from 'react-ga4';
import BuscarAutoescola from './NotificacaoWhatsapp/BuscarAutoescola';
import ParcelasFinanceiro from './ParcelasFinanceiro';
import ContratoLoginInforCFC from './Contrato/LoginInforCFC';
import { inject } from '@vercel/analytics';

inject();
ReactGA.initialize('G-HDLQKJLL5H');
console.log('up');

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/contato/aluno">
              <BuscarAutoescola />
            </Route>
            <Route path="/whatsapp/notificacao/:codigo">
              <NotificacaoWhatsappPage />
            </Route>
            <Route path="/correios/:unidadeId/:codigo">
              <Correios />
            </Route>
            <Route path="/contrato/status">
              <ContratoStatusPage />
            </Route>
            <Route path="/contrato/ler">
              <ContratoMasterPage>
                <ContratoLerPage />
              </ContratoMasterPage>
            </Route>
            <Route path="/contrato/assinar">
              <ContratoMasterPage arrow>
                <ContratoAssinarPage />
              </ContratoMasterPage>
            </Route>
            <Route path="/contrato/completo">
              <ContratoMasterPage>
                <ContratoCompleto />
              </ContratoMasterPage>
            </Route>
            <Route path="/contrato/verificar">
              <ContratoMasterPage arrow>
                <ContratoVerificarPage />
              </ContratoMasterPage>
            </Route>
            <Route path="/contrato/inforcfc/:unidadeId/:codigo">
              <ContratoLoginInforCFC />
            </Route>
            <Route path="/contrato/:unidadeId/:codigo">
              <Contrato />
            </Route>

            <Route path="/financeiro/boletos-abertos/:unidade/:codigo">
              <FinanceiroBoleto />
            </Route>
            <Route path="/financeiro/parcelas/:autoescolaId/:unidadeId/:alunoId/:matriculaId">
              <ParcelasFinanceiro />
            </Route>
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
