import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Container,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import Lottie from "react-lottie";
import aguardando from "../lottie/agardando.json";
import { useParams } from "react-router-dom";
import {
  BuscarFinanceiroBoletosAbertos,
  FinanceiroBoletosAbertos,
} from "../services/AlunoService";
import { makeStyles } from "@material-ui/core/styles";
import { green, red, yellow } from "@material-ui/core/colors";
import { format } from "date-fns";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ImgLogoUnidade from "../Shared/ImgLogoUnidade";
import { clsx } from "clsx";
import useDocumentTitle from "../hooks/useDocumentTitle";

const option = {
  loop: true,
  autoplay: true,
  animationData: aguardando,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    margin: 10,
    [theme.breakpoints.down(`sm`)]: {
      width: "100%",
    },
  },
  containerLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boletoPagoLabel: {
    color: green[700],
    fontWeight: "bolder",
  },
  avatarScheduler: {
    backgroundColor: red[500],
  },
  avatarPago: {
    backgroundColor: green[500],
  },
  avatarEvidente: {
    backgroundColor: yellow[800],
  },
  cardContainer: {
    display: "flex",
    flexGrow: 10,
    flexBasis: 20,
    flexWrap: "wrap",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
}));
const FinanceiroBoleto = () => {
  useDocumentTitle("Financeiro")

  const classes = useStyles();
  const [boletos, setBoletos] = useState<FinanceiroBoletosAbertosView[]>(
    null as any
  );
  let { unidade, codigo } = useParams<any>();
  useEffect(() => {
    BuscarFinanceiroBoletosAbertos(codigo)
      .then((x) => {
        let views = x as FinanceiroBoletosAbertosView[];
        const boletoEvidencia = views.findIndex((y) => y.sit === `A`);
        if (boletoEvidencia > -1) views[boletoEvidencia].evidencia = true;
        /* console.log(boletoEvidencia);
        console.log(views); */
        setBoletos(views);

        setTimeout(() => {
          document.querySelector(`.evidencia`)?.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "center",
          });
        }, 300);
      })
      .catch((x) => {
        alert(x.error);
      });
  }, [codigo]);

  return (
    <Container
     
      style={{
        overflow: `auto`,
        height: `100vh`,
      }}
    >
      <div className={classes.containerLogo}>
        <ImgLogoUnidade unidadeId={unidade} />
        <Typography variant={"h5"} align={"center"}>
          SUAS PARCELAS
        </Typography>
      </div>

      {boletos === null ? (
        <Box mt="30px">
          <Lottie options={option} height={250} width={250} />
          <Typography variant={"h2"} align={"center"}>
            Aguarde...
          </Typography>
        </Box>
      ) : (
        <div className={classes.cardContainer}>
          {boletos.length ? (
            boletos.map((x, i) => (
              <CardBoleto boleto={x} key={x.linhaDigitavel} index={i} />
            ))
          ) : (
            <h2>Sem resultados</h2>
          )}
        </div>
      )}
    </Container>
  );
};

interface CardBoletoProperties {
  boleto: FinanceiroBoletosAbertosView;
  index: number;
}

interface FinanceiroBoletosAbertosView extends FinanceiroBoletosAbertos {
  evidencia: boolean;
}

const CardBoleto = ({ boleto, index }: CardBoletoProperties) => {
  const classes = useStyles();

  function abrirBoleto(url: string) {
    window.open(url);
  }

  return (
    <>
      <Card
        className={"evidencia " + classes.root}
        variant={"elevation"}
        elevation={boleto.evidencia ? 10 : 2}
      >
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={clsx({
                [classes.avatarPago]: boleto.sit === `P`,
                [classes.avatarScheduler]:
                  boleto.sit === `A` && !boleto.evidencia,
                [classes.avatarEvidente]:
                  boleto.sit === `A` && boleto.evidencia,
                // "evidencia":true
              })}
            >
              {boleto.sit === `A` && boleto.evidencia && <EventIcon />}
              {boleto.sit === `A` && !boleto.evidencia && <ScheduleIcon />}
              {boleto.sit === `P` && `P`}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              {/*<MoreVertIcon />*/}
            </IconButton>
          }
          title={boleto.descricao}
          subheader={
            "Vencimento " + format(new Date(boleto.vencimento), `dd/MM/yyyy`)
          }
        />

        <CardContent style={{ whiteSpace: "pre-wrap" }}>
          {boleto.evidencia ? (
            <CopyToClipboard
              text={boleto.linhaDigitavel}
              onCopy={() => alert(`Copiado`)}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ whiteSpace: "pre-wrap" }}
              >
                <Link
                  color={"primary"}
                  noWrap={false}
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                  }}
                >
                  {boleto.linhaDigitavel}
                </Link>
              </Typography>
            </CopyToClipboard>
          ) : (
            <Typography
              variant="body2"
              style={{ width: "100%", display: "block" }}
              component="span"
              align={"center"}
              className={boleto.sit === `P` ? classes.boletoPagoLabel : ``}
            >
              {boleto.sit === `A`
                ? "Pagamento ainda não disponível"
                : "PAGO"}
            </Typography>
          )}
        </CardContent>
        {boleto.evidencia && (
          <CardActions disableSpacing>
            <Button
              size="small"
              color="primary"
              onClick={() => abrirBoleto(boleto.urlParcela)}
            >
              {boleto.linhaDigitavel ? "visualizar boleto" : "visualizar cobrança"}
            </Button>
            {boleto.linhaDigitavel && (
              <CopyToClipboard
                text={boleto.linhaDigitavel}
                onCopy={() => alert(`Copiado`)}
              >
                <Button size="small" color="primary">
                  Copiar código
                </Button>
              </CopyToClipboard>
            )}
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default FinanceiroBoleto;
