import { useRef, useEffect } from 'react'
import ReactGA from "react-ga4";

function useDocumentTitle(title:string, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });

  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle