import styled from "styled-components";
import {Button, Container, Typography} from "@material-ui/core";


export const ContainerWapper = styled.div`
  //position: relative;
  //width: 400px;
  //height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

 
  .sua-assinatura {
    position: relative;
    bottom: 30px;
    width: 80%;
    text-align: center;
    border-top: 1px solid black;
    -moz-user-select: none;
    -webkit-user-select: none;
    pointer-events: none;

    -ms-user-select: none;
    user-select: none;
   justify-content: space-between;
  }

  .signature-pad {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;
    border: 1px dashed #c3c3c3;
    min-height:200px;
    max-height:300px;

  }

`;

export const ContainerAssinatuna = styled(Container)`
  //border:1px solid red;
  padding-bottom:50px;
  padding-top: 3rem;
`;


export const Titulo = styled(Typography)`
  width: 100%;
  text-align: center;

`;
export const TituloCima = styled(Typography)`
  width: 100%;
  text-align: center;
  padding-top: 2rem;
`;

export const ContainerLimpar = styled.div`
  width: 100%;
  text-align: center;
  
  
`;
export const BotaoFinalizar = styled(Button)`
  margin-top:  50px;
  width: 60%;

`;

