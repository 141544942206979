import { createContext, useEffect, useState } from "react";
import useStorage from "../hooks/useStore";
import { Logar } from "../services/AlunoContratoService";
import { AlunoContratoLoginView } from "../models/AlunoContratoLoginView";
import { ServiceBase } from "../services/ServiceBase";

import { useHistory } from "react-router-dom";
import { AlunoMin } from "../models/AlunoMin";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import {Alert, Color } from "@material-ui/lab";
import { AlertTitle } from "@material-ui/lab";

export interface State extends SnackbarOrigin {
  open: boolean;
  type: Color;
  message: string;
  title?: string;
}

interface AuthContextProps {
  token?: string;
  statusContrato: { id: number; descricao: string };

  login(obj: any): any;

  aluno: AlunoMin;
  setAlert(state: State): void;
  alert: State;
}

export const AuthContext = createContext<AuthContextProps>({} as any);

export const AuthProvider = ({ children }: any) => {
//   const location = useLocation();
//   const obj = useRouteMatch();

//   useEffect(() => {
//     console.log("Location changed");
//     console.log(" location.key", location);

//     ReactGA.event("pageview", location.pathname + location.search);

//   }, [location,obj]);

  const history = useHistory();
  const [token, setToken] = useStorage<string>(`token`);
  const [statusContrato, setStatusContrato] = useStorage<{
    id: number;
    descricao: string;
  }>("statusContrato");
  const [aluno, setAluno] = useStorage<AlunoMin>("aluno");

  const [alert, setAlert] = useState<State>({
    open: false,
    horizontal: "right",
    vertical: "bottom",
    type: "success",
    message: "Olá! ",
  });
  const { vertical, horizontal, open, type, message, title } = alert;
  useEffect(() => {
    ServiceBase.instance.defaults.headers.common["Authorization"] =
      "bearer " + token;

    ServiceBase.instance.defaults.headers["Authorization"] = "bearer " + token;
    if (!token) return;
  }, [token]);

  async function login(obj: AlunoContratoLoginView) {
    try {
      let result = await Logar(obj);
      if (!result.token || !result.status) {
        return null;
      }

      setToken(result.token);
      setStatusContrato(result.status);
      setAluno(result.aluno);
      
      if (result.status.id === 0) history.push("/contrato/ler");
      else history.push("/contrato/status");

      return result;
    } catch (error: any) {
      setAlert({
        ...alert,
        open: true,
        type: "error",
        message: error?.response?.data?.result || "Aluno não encontrado!",
      });

      return null;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        token: token,
        login,
        statusContrato,
        aluno,
        setAlert,
        alert,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() => setAlert({ ...alert, title: "", open: false })}
        message=""
        key={vertical + horizontal}
        autoHideDuration={4000}
      >
        <Alert elevation={6} variant="filled" severity={type}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};
