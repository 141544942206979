import styled from "styled-components";
import {Typography} from "@material-ui/core";

export const StatusContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 5rem;
  text-align: center;
  text-transform: uppercase;
  .mensagem{
    margin-top: 8rem;
  }
`;

export const Mensagem = styled(Typography)`
  font-weight: bold;
  color: #087f3b;
`;