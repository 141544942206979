import React, {useContext} from "react";

import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {faAddressCard, faCalendarAlt} from "@fortawesome/free-regular-svg-icons";

import {makeStyles} from "@material-ui/core/styles";
import {Field as FieldFormik, Form, Formik} from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import {Box, Container, InputAdornment, TextField} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import parse from "date-fns/parse";
import {isDate} from "date-fns";
import ImgLogoUnidade from "../Shared/ImgLogoUnidade";
import {useParams} from "react-router-dom";
import {AuthContext} from "../Context/AuthContext";
import useDocumentTitle from "../hooks/useDocumentTitle";

let cpfSchema = Yup.string()
  .required(`Campo obrigatório`)
  .test("cpf_invalido", "Cpf inválido", (x: any) =>
    Promise.resolve(isValidCPF(x))
  );
function parseDateString(value: any, originalValue: any) {
  return isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd/MM/yyyy", new Date());
}
let dateSchema = Yup.date()
  .transform(parseDateString)
  .required(`Campo obrigatório`);
const formSchema = Yup.object().shape({
  cpf: cpfSchema,
  dataNascimento: dateSchema
});
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  logo: {
    maxWidth: "260px",
    marginBottom: "4rem"
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Created with by <FontAwesomeIcon icon={faHeart} />
      &nbsp;
      <Link color="inherit" href="http://inforcfc.com.br/">
        Inforcfc.com.br
      </Link>{" "}
      <br />
      {"Copyright © "}
      {new Date().getFullYear()}
      {".."}
    </Typography>
  );

}


export interface LoginProps {
  cpf: string;
  dataNascimento: string;
}
const Contrato: React.FC = () => {
  useDocumentTitle("Contrato - login")

  const authContext = useContext(AuthContext);
  const {alert,setAlert,aluno} = authContext;
  let { unidadeId,codigo } = useParams<any>();

  const classes = useStyles();



  function toTitleCase(str:string) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
  }

  const submit = async (values: LoginProps, setSubmitting: any) => {
    const { cpf, dataNascimento } = values;
    setSubmitting(true);
    console.log(values);

    let result =await authContext.login({
      cpf,
      dataNascimento,
      unidadeId,
      codigo
    });

  
    if (!result) {

      setSubmitting(false);
      return ;
    }
    setAlert({
      ...alert,
      open: true,
      type: "success",
      message: `Olá ${toTitleCase(aluno.nome).split(' ')[0]}`
    });
  };
  const login: LoginProps = {
    cpf: ``,
    dataNascimento: ``
  };
  return (
    <>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <ImgLogoUnidade unidadeId={unidadeId} className={classes.logo} />
          {/* <Avatar className={classes.avatar}>
            <FontAwesomeIcon icon={faLock} />
          </Avatar> */}

          <Formik
            initialValues={login}
            validationSchema={formSchema}
            
            onSubmit={async (values, { setSubmitting }) => {
              await submit(values, setSubmitting);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form   noValidate
             
              translate="no"
              onSubmit={e=>{
                submitForm();
                e.preventDefault();
                return false;
              }} className={classes.form}>
                <FieldFormik
                  name="cpf"
                  render={({ field, ...outros }: any) => (
                    <TextField
                      {...field}
                      required
                      
                      error={!!outros.meta?.error && outros.meta.touched}
                      helperText={outros.meta?.error}
                      variant="outlined"
                      label="CPF"
                      fullWidth
                      id="DataNascimento"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon
                              size="lg"
                              color="#c3c3c3"
                              icon={faAddressCard}
                            />
                          </InputAdornment>
                        ),
                        inputComponent: TextMaskCustom as any
                      }}
                    />
                  )}
                />

                <br />
                <br />
                <FieldFormik
                  name="dataNascimento"
                  render={({ field, ...outros }: any) => (
                    <TextField
                      {...field}
                      required
                      error={!!outros.meta?.error && outros.meta.touched}
                      variant="outlined"
                      label="Data nascimento"
                      fullWidth
                      id="dataNascimento"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon
                              size="lg"
                              color="#c3c3c3"
                              icon={faCalendarAlt}
                            />
                          </InputAdornment>
                        ),
                        inputComponent: TextMaskCustomDate as any
                      }}
                    />
                  )}
                />
                {isSubmitting && <LinearProgress />}
                <br />
                <Button
                  variant="contained"
                  className={classes.submit}
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  type="submit"
                >
                  Entrar
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>

    </>
  );
};

export default Contrato;

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

function TextMaskCustomDate(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
    />
  );
}

/* eslint eqeqeq: "off", curly: "off", no-redeclare:"off" */

function isValidCPF(cpf: string) {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[\s.-]*/gim, "");
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  ) {
    return false;
  }
  var soma = 0;
  var resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (var i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
}
