import axios from "axios";
import environment from "./environment";

// export const ServiceBase = axios.create({
//   baseURL: "http://localhost:5000/api/",
//   // timeout:1
//   // baseURL: "http://icfc.guiadocondutor.com.br:5000/api/"
// });

export const Post = async <T>(url: string, data: any) => {
  let result = await ServiceBase.instance.post<T>(url, data);
  return result.data;
};

export const Get = async <T>(url: string) => {
  const result = await ServiceBase.instance.get<T>(url);
  return result.data;
};

export class ServiceBase {
  static instance = axios.create({
    baseURL: environment.url_api_service + "api/"
    // timeout:1
    // baseURL: "http://icfc.guiadocondutor.com.br:5000/api/"
  });
}
