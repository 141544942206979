import { Button, Card, Container, Typography } from "@material-ui/core";
import { CadastroAutoescola } from "../../services/AlunoService";

interface Props {
  autoescola: CadastroAutoescola;
}

const CardAutoescola: React.FC<Props> = ({ autoescola }) => {

  const handleDataUnidade = (codigo: string) => {
    const url = `https://minhacnh.online/whatsapp/notificacao/${encodeURI(codigo)}`;
    window.open(url, "_blank");
  };

  return (
    <Card
      style={{
        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 8,
        paddingBottom: 8,
        marginTop: 8,
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="logo"
          src={`https://storage.googleapis.com/inforcfc_store/logo_unidade/${
            autoescola.unidadeId
          }.jpg?r=${new Date().getTime()}`}
          style={{
            objectFit: "cover",
            width: 120,
            height: "auto",
          }}
        />
      </Container>
      <Container
        style={{
          marginTop: 16,
        }}
      >
        <Typography
          variant="body2"
          style={{
            marginBottom: 8,
            fontSize: 12,
            fontWeight: 600,
            color: "#999898",
          }}
        >
          Auto Escola
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: 8,
            fontSize: 12,
            fontWeight: 500,
            color: "#333",
          }}
        >
          {autoescola.unidadeNome}
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: 8,
            fontSize: 12,
            fontWeight: 500,
            color: "#999898",
          }}
        >
          Cadastrado como:
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: 8,
            fontSize: 12,
            fontWeight: 500,
            color: "#3a3a3a",
          }}
        >
          {autoescola.alunoNome}
        </Typography>
      </Container>
      <Button
        variant="text"
        color="secondary"
        onClick={() => handleDataUnidade(autoescola.codigo)}
        style={{ width: "90%" }}
      >
        Ver Dados da unidade
      </Button>
    </Card>
  );
}

export default CardAutoescola;