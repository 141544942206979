
const storage = {
    set: (key: string, value:any) => localStorage.setItem(key, JSON.stringify(value)),
    get: <T>(key: string) => {
      const item = localStorage.getItem(key);
      if (item) return JSON.parse(item) as T;
      return null;
    },
    remove: (key: string) => {
      localStorage.removeItem(key);
    }
  };
  
  export default storage;
  
  
  // const storage = {
  //   set: (key: string, value) => localStorage.setItem(key, JSON.stringify(value)),
  //   get: <T>(key: string) => {
  //     const item = localStorage.getItem(key);
  //     if (item) {
  //       if (item.startsWith("{") || item.startsWith("[")) {
  //         return JSON.parse(item) as T;
  //       }
  //       return item;
  //     }
  //     return null;
  //   },
  //   remove: (key: string) => {
  //     localStorage.removeItem(key);
  //   }
  // };
  
  // export default storage;
  