import React, {useState} from 'react';
import {AlunoNotificacao, PainelNotificacaoSalvar} from "../services/AlunoService";
import Card from "@material-ui/core/Card";
import {Box, Button, CardActionArea, Grid, Switch} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import {makeStyles} from "@material-ui/core/styles";
import {State} from "../Context/AuthContext";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        background: 'linear-gradient(176deg, rgba(2,0,36,1) 0%, rgba(9,9,121,0.4318102240896359) 100%, rgba(0,212,255,1) 100%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
        color: 'white',
        marginTop: '10px',
        padding: '0 30px',

    },
    media: {
        height: 140,
    },
    baixar: {
        maxWidth: 345,
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        marginTop: '10px',
        padding: '0 30px',
    },
});
const AlunoNotificacaoCard: React.FC<{ alunoModel: AlunoNotificacao, codigo: string, setAlert: any }> = ({
                                                                                                             alunoModel,
                                                                                                             codigo,
                                                                                                             setAlert
                                                                                                         }) => {
    const [aluno, setAluno] = useState<AlunoNotificacao>(alunoModel);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const mudarStatusNotificacao = async (checked: boolean) => {
        try {

            setAluno({...aluno, receberNotificacao: checked})
            await PainelNotificacaoSalvar(codigo, checked)
            setAlert((alert: any, props: any) => ({
                ...alert,
                title: "Salvo com sucesso!",
                type: "success",
                open: true,
                message: ""
            }))
        } catch (e) {
            setAluno({...aluno, receberNotificacao: !checked})
            setAlert({...alert, title: "Erro ao salvar", type: "error", open: true})
        } finally {
            setLoading(false)
        }

    }
    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <Typography variant="h5" component="h2" align={"center"}>
                        {aluno.nome}
                    </Typography>
                    <Typography align={"center"} variant="body1" component="p">
                    <b>    Deseja receber notificações por whatsapp?</b>
                    </Typography>
                    <Box display="flex" flexDirection="row" alignContent="center" alignItems="center"
                         justifyContent="center" width={"100%"}>
                        Não <Switch disabled={loading} checked={aluno.receberNotificacao}
                                    onChange={x => mudarStatusNotificacao(x.target.checked as any)}
                                    color="secondary"
                                    name="checkedC"/> Sim
                    </Box>
                </CardContent>
            </Card>

            <Card  className={classes.baixar}>


                <CardContent>
                    <Box display="flex" flexDirection="row" alignContent="center" alignItems="center" flexWrap="wrap"
                         justifyContent="center" className={classes.baixar}>

                        <Typography align="center" variant="body1"
                                    style={{width: '100%', paddingTop: '5px', paddingBottom: '10px'}}><b>Acompanhe seu
                            processo CNH pelo celular:</b></Typography>
                        <br/>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=inforcfc.minhacnh"><img
                            src="/assets/img/googleplay.png" style={{width: '120px', paddingTop: ''}}/></a>

                        <a target="_blank" href="https://apps.apple.com/br/app/minha-cnh-online/id1554140469">
                            <img src="/assets/img/appstore.png" style={{width: '110px', padding: '1rem'}}/></a>
                    </Box>

                </CardContent>


            </Card>
        </>
    );
};

export default AlunoNotificacaoCard;
