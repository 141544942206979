import React, {useMemo} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import {Unidade} from "../services/AlunoService";
import {Box, Button, CardActionArea, styled} from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import CallIcon from '@material-ui/icons/Call';
import NavigationIcon from '@material-ui/icons/Navigation';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    whatsapp: {
        backgroundColor: "#128c7e",
        color: "white"
    },
    tel: {
        backgroundColor: "#00a99b",
        color: "white"
    },
    instagram: {
        backgroundColor: "#c13584",
    },
    facebook: {
        backgroundColor: "#4267b2",
    },
    twitter: {
        backgroundColor: "#1da1f2",
    },
    navegar: {
        backgroundColor: "#005f9b",
    },
    btnMedia: {
        borderRadius: 0
    }
});
const ButtonMedia = styled(Button)({
    borderRadius: 0,
    width: "100px",
    padding: "8px",
    color: "white",
    margin: "3px"

})
const UnidadeContatoCard: React.FC<{ unidade: Unidade }> = ({unidade}) => {
    const {id, redeSocial} = unidade;

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const enderecoCompleto = useMemo(() => {
        if (!unidade) return "";
        return `${unidade.endereco} ${unidade.enderecoNumero}, ${unidade.bairro}, ${unidade.cidade} - ${unidade.uf}`;

    }, [unidade]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    function openMaps(enderecoCompleto: string) {
        window.open(
            "https://maps.google.com/maps?daddr=" + enderecoCompleto,
            '_system'
        );
    }

    function openTelefone(fone: string) {
        window.open("tel:" + fone,
            '_system');

    }

    function openTwitter(t: string) {
        window.open("https://twitter.com/" + t, '_system', 'location=no');
    }

    function openInstagram(t: string) {
        window.open("https://www.instagram.com/" + t, '_system', 'location=no');
    }

    function openFacebook(t: string) {
        window.open("https://www.facebook.com/" + t, '_system', 'location=no');
    }

    function openWhatsapp(whats: string) {
        const link = `https://api.whatsapp.com/send?phone=${whats}&text=`;
        let text = `Poderia me ajudar?`;

        var encode = encodeURI(text);
        window.open(link + encode, '_system', 'location=no');
    }

    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={`https://storage.googleapis.com/inforcfc_store/logo_unidade/${unidade.id}.jpg`}
                title={unidade.nome}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {unidade.nome}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {enderecoCompleto}
                    <br/>
                    <br/>
                    Telefone: {unidade.fone}
                    <br/>
                    Whatsapp: {unidade?.whatsTel}
                </Typography>
            </CardContent>
             <CardActions>
                <Box display="flex" justifyContent="space-between" flexDirection="row" flexWrap="wrap">
                    {unidade.fone &&
                        <ButtonMedia onClick={x => openTelefone(unidade.fone)} className={classes.tel} size="small">
                            <CallIcon></CallIcon>
                        </ButtonMedia>}

                    {unidade?.whatsTel &&
                        <ButtonMedia onClick={() => openWhatsapp(unidade?.whatsTel)} className={classes.whatsapp}
                                     size="small">
                            <WhatsAppIcon></WhatsAppIcon>
                        </ButtonMedia>
                    }
                    {enderecoCompleto &&
                        <ButtonMedia onClick={() => openMaps(enderecoCompleto)} className={classes.navegar}
                                     size="small">
                            <NavigationIcon></NavigationIcon>
                        </ButtonMedia>
                    }
                    {redeSocial?.instagram &&
                    <ButtonMedia onClick={() => openInstagram(redeSocial?.instagram)} className={classes.instagram} size="small">
                        <InstagramIcon></InstagramIcon>
                    </ButtonMedia>}
                    {redeSocial?.facebook &&
                    <ButtonMedia onClick={() => openFacebook(redeSocial?.facebook)}  className={classes.facebook} size="small">
                        <FacebookIcon></FacebookIcon>
                    </ButtonMedia>}
                    {redeSocial?.twitter &&
                        <ButtonMedia onClick={() => openTwitter(enderecoCompleto)} className={classes.twitter} size="small">
                            <TwitterIcon></TwitterIcon>
                        </ButtonMedia>
                    }

                </Box>


            </CardActions>
        </Card>
    );
}
export default UnidadeContatoCard;
